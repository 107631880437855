import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import cx from 'classnames';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Button from 'components/Button';
import styles from './careers.module.scss';

const TeamPage = () => {
  return (
    <Layout>
      <SEO title="Careers" />
      <section className="content">
        <h1>Careers</h1>
        <div className={cx(styles.mainImage)}>
          <StaticImage
            src="../_assets/images/group1.png"
            alt="Members of the GE-WS team"
          />
        </div>
        <p>
        Careers Page is coming soon... 
        </p>
        
        <p>
          <Button href="#">
            See open roles
          </Button>
        </p>

        <p>
          Don't see the role you are looking for? Drop us a line at{' '}
          <a href="mailto:info@gews.edu.gh">info@gews.edu.gh</a>{' '}
          with a write-up about your ideal job, why you want to join the team,
          and a copy of your resume and someone will get back to you.
        </p>
      </section>
    </Layout>
  );
};

export default TeamPage;
